import React from "react";
import "../styles/AboutWelcome.css";
import {HashLink as Link} from 'react-router-hash-link';
import Navbar2 from "../components/Navbar2";

function AboutWelcome() {
  return (
    <div className="aboutWelcome" id="aboutWelcome">
      <div className="aboutWelcomeNav">
        <a href="https://www.soundsummoner.com"><div className="logo">Name/Logo Here</div></a>
        <a href="https://about.soundsummoner.com"><h3>ABOUT</h3></a>
        {/* <Link to="/about"><h3>About</h3></Link> */}
        <Link><h3></h3></Link>
        <a href="https://gallery.soundsummoner.com"><h3>GALLERY</h3></a>
        <Link><h3>MENU</h3></Link>
        <a href="https://contact.soundsummoner.com"><h3>CONTACT</h3></a>
      </div>

    </div>
  // </div> */}
    );
  }

export default AboutWelcome;
