import React, { useState, useEffect } from 'react';
import "./App.css";
import moon from './assets/squiremain.JPG';
import land from './assets/SquireCapture4.JPG';
import cat from './assets/squireCapture3.JPG';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import Footer from "./components/Footer";
import Bottom from "./pages/Bottom";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Navbar2 from "./components/Navbar2";
// import Community from "./pages/Community";
import AboutWelcome from "./pages/AboutWelcome";
import Parallax2 from './components/2Parallax';

import MultiplePizzas from "./assets/pizza.jpeg";
import HoriParallax from './components/HoriParallax';
function App() {

  //   function changeWindowSize(){
  
  //   const [x,setx] = useState(3);
  //   useEffect(() => {
  //     const handleResize = () => {
  //       if (window.innerWidth > 767) {
  //         setx(3);
  //         console.log('big window' + x);
  //       } else if (window.innerWidth < 767) {
  //         console.log('small window' + x);
  //         setx(5);
  //       }
  //     };
  //     window.addEventListener('resize', handleResize);
  //     return() => {
  //       window.removeEventListener('resize', handleResize);
  //     }
  //   }, []);  
  //   return x;
  // }


  // if (window.location.href != "https://www.norcalhost.com/") {
  //   // console.log('works great!!');
  //   window.location = "https://www.norcalhost.com";
  // }  
  
  
  const [x,setx] = useState(false);
    
        // console.log('still');
      // useEffect((varx) => {
      //   if(x === true) {
      //     console.log(window.location.href);
      //     if (window.screen.width < 950 && window.location.href === "http://localhost:3000/") {
            
      //       // if (window.screen.width < 950) {
      //         // console.log(x);
      //         // setx(false);
      //         console.log(x);
      //         window.location = "./about";
      //         // console.log('working');
      //         // RedirectExample();
      //         //  }
      //         // return setx(false)
            

  return (
    <div className="App">
      <Router>

      {/* <Routes> */}
        <Route exact path="/">
        <Parallax pages={2.55} 
        style={{backgroundColor: 'black'}}
        >
          <ParallaxLayer
            // className="Cat"
            speed={1}
            factor={3}
            offset={0}
            >
            <div>
            <Navbar2 />
            <AboutWelcome />

            </div>

          </ParallaxLayer>
          <ParallaxLayer
            className="Cat"
            speed={1}
            factor={2}
            offset={.95}
            style={{
              // width: '100%',
              // hieght: '100%',
              // backgroundColor: "black"
            }}
            // sticky={{ 
            //   // start: 0.9
              // start: 1.5
            //   , end: 2.5 
            // }}
            >
    <div>
            <About/>
            {/* <Menu/> */}
            <HoriParallax />
    </div>
            
          </ParallaxLayer>
          
        <ParallaxLayer
          className="Moon"
          offset={0}
          speed={.5}
          factor={1}
          style={{
            zIndex: '-5',
            backgroundImage: `url(${moon})`,
            backgroundSize: 'cover',
          }}
          >
          <div>

          </div>
          </ParallaxLayer>

            <ParallaxLayer
              className="AboutFooter"
              speed={2}
              factor={1}
              offset={2}
              style={{
                width: '100%',
                hieght: '100%',
                backgroundColor: "black"
                // zIndex: '-5',
                // background-image: url("./assets/fabricBackground.jpg"),
                // backgroundImage: `url(${cat})`,
                // backgroundSize: 'cover',
              }}
              >
            {/* <AboutWelcome></AboutWelcome> */}
            {/* <Contact></Contact> */}
            {/* <Footer></Footer> */}
            </ParallaxLayer>
            <ParallaxLayer
              className="Land"
              offset={1.5}
              speed={.5}
              factor={1}
              style={{
                // width: '100%',
                // height: '100%',
                // zIndex: '-5',
                // backgroundImage: `url(${land})`,
                backgroundSize: 'cover',
                
              }}
              >
            <div>
              {/* <div className="landText">

            <p>first + {x}</p>
              </div> */}
          <ParallaxLayer
            // className="Cat"
            speed={1}
            factor={3}
            offset={1.3}
            >
            <div>
            <Bottom />

            </div>

          </ParallaxLayer>
                {/* <Contact/> */}
                {/* <Footer /> */}
            {/* <Footer></Footer> */}
            </div>
              {/* <Contact></Contact> */}
            </ParallaxLayer>

        </Parallax>
    {/* } */}
      </Route>

{/* ///////////////////////////about//////////////////////// */}
    
      <Route exact path="/about">
          <Parallax2></Parallax2>
          <About></About>
      </Route>

{/* ///////////////////contact//////////////////////////////////////// */}

      <Route exact path="/contact">

      <Parallax pages={1.7} 
        style={{backgroundColor: 'black'}}
        >
      <ParallaxLayer
            // className="Cat"
            speed={1}
            factor={1}
            offset={0}
            style={{
              width: '100%',
              hieght: '100%',
            //   zIndex: '-5',
              backgroundSize: 'cover',
              backgroundImage: `url('https://images.unsplash.com/photo-1485770958101-9dd7e4ea6d93?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80')`,
            }}
            >
            <div>
            <AboutWelcome />

            </div>

          </ParallaxLayer>
            {/* <Home /> */}
      {/* <Parallax2 /> */}
      
      {/* <Menu /> */}

      <ParallaxLayer
                  speed={1}
                  factor={.8}
                  offset={.8}
      >
      <div>

      <Contact />
      </div>
      </ParallaxLayer>

      </Parallax>

      </Route>

      {/* </Routes> */}
      </Router>
    </div>
  );
}

export default App;
