import React from "react";
import "../styles/Bottom.css";
import {HashLink as Link} from 'react-router-hash-link';

function Bottom() {
  return (
    <div className="Bottom" id="Bottom">

    <h1>PRIVATE EVENTS</h1>
    <a href="https://squirecontact.norcalhost.com">
      <h3>LEARN MORE</h3>
    </a>
    <br></br><br></br><br></br><br></br>
    <p> &copy; A Company of Redding, CA </p>
    
    </div>
  // </div> */}
    );
  }

export default Bottom;
